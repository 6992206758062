import _ from 'lodash';
import { useEffect, useState } from 'react';

import { getCarrierServices } from '../services/carriers';
import { DisplayValue } from '../types';

export function useCarrierServices() {
  const [carrierServicesData, setCarrierServicesData] = useState<DisplayValue<string>[]>([]);

  useEffect(() => {
    const fetchCarrierServices = async () => {
      const _carrierServices = await getCarrierServices();
      const data = _.uniqBy(
        _carrierServices.map((cs) => ({ label: cs.name, value: cs.key })),
        (cs) => cs.value,
      );
      setCarrierServicesData(data);
    };

    if (_.isEmpty(carrierServicesData)) {
      fetchCarrierServices();
    }
  }, []);

  return carrierServicesData;
}
