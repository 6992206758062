import _ from 'lodash';
import React from 'react';

import { TextField } from '@cimpress/react-components';

import { DEFAULT_INPUT_HEIGHT } from '../../constants/stylingConstants';

type TextFieldPropsOverride = Omit<React.ComponentProps<typeof TextField>, 'size'> & {
  inputStyles?: Record<string, string>;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  height?: 'md' | 'sm';
};

type StyledTextFieldProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  height?: 'md' | 'sm';
};

const StyledTextField: React.FC<StyledTextFieldProps & TextFieldPropsOverride> = ({
  size = 'md',
  height = 'md',
  ...props
}) => {
  let width = 200;
  if (size) {
    switch (size) {
      case 'lg':
        width = 300;
        break;
      case 'md':
        width = 200;
        break;
      case 'sm':
        width = 175;
        break;
      case 'xs':
        width = 150;
        break;
    }
  }

  const inputHeight = height === 'md' ? DEFAULT_INPUT_HEIGHT : '48px';

  // Unfortunately must be passed as a raw object
  const inputStyles = _.merge(
    {
      height: inputHeight,
      width: `${width}px`,
    },
    props.inputStyles,
  );

  const topLevelStyles = {
    marginBottom: '10px',
    marginRight: '10px',
  };

  return <TextField {...props} style={topLevelStyles} inputStyle={inputStyles} />;
};

export default StyledTextField;
