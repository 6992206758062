import { Environment } from '../features/resourceSearch/resourceSearchSlice';
import fetchWithAuth from '../utils/fetchWithAuth';
import { ItemResponse } from './items';

export type FulfillmentGroup = {
  localPromisedArrivalDate: string;
  items: ItemResponse[];
};

export type OrderResponse = {
  createdDate: string;
  fulfillmentGroups: FulfillmentGroup[];
  merchantId: string;
  orderId: string;
};

export const getOrder = async (orderId: string, env: Environment): Promise<OrderResponse> => {
  const url =
    env === 'prd'
      ? 'https://order-manager.commerce.cimpress.io'
      : 'https://int-order-manager.commerce.cimpress.io';

  return await fetchWithAuth({
    endpointUrl: url,
    route: `/v1/orders/${orderId}`,
  });
};
