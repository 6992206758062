import _ from 'lodash';

import { getAccessToken } from '../auth';

type MerchantData = {
  merchantId: string;
};

export type RequirementData = {
  _links: {
    self?: {
      href: string;
    };
  };
  name: string;
};

const constructHeaders = () => ({
  Authorization: `Bearer ${getAccessToken()}`,
  'Content-Type': 'application/json',
});

export const getMerchants = async () => {
  const params = {
    method: 'get',
    headers: constructHeaders(),
    qs: {
      verbose: false,
      active: true,
    },
  };
  const url = `${process.env.REACT_APP_MERCHANT_CONFIG_URL}/v0/merchants`;
  const response = await fetch(url, params);
  if (response.ok) {
    const data: MerchantData[] = await response.json();
    return _.keyBy(data, 'merchantId') as Record<string, MerchantData>;
  }
  throw new Error(`Error retrieving merchants, received status code ${response.status}`);
};
