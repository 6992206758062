import _ from 'lodash';
import { useEffect, useState } from 'react';

import { DisplayValue } from '../types';
import { getFulfillmentExpectations } from '../utils/getFulfillmentExpectations';

export function useFulfillmentExpectations() {
  const [fulfillmentExpectationsData, setFulfillmentExpectationsData] = useState<
    Record<string, DisplayValue<string>>
  >({});

  useEffect(() => {
    const fetchFulfillmentExpectations = async () => {
      const _fulfillmentExpectations = await getFulfillmentExpectations();
      const data = _(_fulfillmentExpectations)
        .entries()
        .reduce(
          (
            accumulator,
            [
              name,
              {
                _links: { self },
              },
            ],
          ) => {
            accumulator[name] = { label: name, value: self!.href };
            return accumulator;
          },
          {} as Record<string, DisplayValue<string>>,
        );

      setFulfillmentExpectationsData(data);
    };

    if (_.isEmpty(fulfillmentExpectationsData)) {
      fetchFulfillmentExpectations();
    }
  }, []);

  return fulfillmentExpectationsData;
}
