import _ from 'lodash';
import moment from 'moment';

import { OrderItem } from '../components/configurationTester/inputs/OrderItems';
import { TestInputState } from '../features/testInput/testInputSlice';
import { ItemWithOrderMetadata } from '../services/items';
import {
  RouteV0RequestBody,
  RouteV0RequestOverrideDeliveryDetails,
  RouteV0RequestOverrideValues,
} from '../services/testRoutingDecision';
import { LinkWithName, RoutingConfigurationV3, WorkingRoutingConfigurationV3 } from '../types';
import { convertWorkingConfigToDbConfig } from './conversions';

export function assembleRoutingEvalArgs({
  inputState,
  accountId,
  selectedItem,
  fulfillmentOptionsLink,
  routingRequestLink,
  workingConfiguration,
  baseConfiguration,
  orderItems,
  productConfigurationUrl,
}: {
  inputState: TestInputState;
  accountId: string;
  selectedItem: ItemWithOrderMetadata;
  fulfillmentOptionsLink?: LinkWithName;
  routingRequestLink: LinkWithName;
  workingConfiguration: WorkingRoutingConfigurationV3;
  baseConfiguration: RoutingConfigurationV3;
  orderItems: OrderItem[];
  productConfigurationUrl?: string;
}) {
  const overrideValuesSet = new Set(inputState.overrideValues);
  const overrideValues: RouteV0RequestOverrideValues = {};
  if (selectedItem._links.productConfiguration.href !== productConfigurationUrl) {
    overrideValues.productConfigurationUrl = productConfigurationUrl;
  }
  if (overrideValuesSet.has('merchantId')) {
    overrideValues.merchantId = inputState.merchantId;
  }
  if (overrideValuesSet.has('fulfillmentCaps')) {
    overrideValues.fulfillmentCapabilities = inputState.fulfillmentCaps;
  }
  if (overrideValuesSet.has('promisedArrivalDate')) {
    overrideValues.localPromisedArrivalDate = moment.isMoment(inputState.promisedArrivalDate)
      ? inputState.promisedArrivalDate.format('YYYY-MM-DD')
      : inputState.promisedArrivalDate;
  }
  if (overrideValuesSet.has('orderDate')) {
    overrideValues.routingTime = moment.isMoment(inputState.orderDate)
      ? inputState.orderDate.format('YYYY-MM-DDTHH:mm:ss[Z]')
      : inputState.orderDate;
  }

  const overrideDeliveryDetails: RouteV0RequestOverrideDeliveryDetails = {};
  if (overrideValuesSet.has('country') && inputState.country) {
    overrideDeliveryDetails.country = inputState.country;
  }
  if (overrideValuesSet.has('stateOrProvince') && inputState.stateOrProvince) {
    overrideDeliveryDetails.stateOrProvince = inputState.stateOrProvince;
  }
  if (overrideValuesSet.has('postalCode')) {
    overrideDeliveryDetails.postalCode = inputState.postalCode;
  }
  if (overrideValuesSet.has('isPoBox')) {
    overrideDeliveryDetails.isPOBox = inputState.isPoBox;
  }
  if (overrideValuesSet.has('deliveryConstraints')) {
    overrideDeliveryDetails.deliveryConstraints = inputState.deliveryConstraints;
  }

  if (!_.isEmpty(overrideDeliveryDetails)) {
    overrideValues.deliveryDetails = overrideDeliveryDetails;
  }

  const normalizedConfiguration = convertWorkingConfigToDbConfig({
    workingConfiguration,
    baseConfiguration,
  });

  const links = {
    buyerAccount: {
      href: `https://accounts.cimpress.io/api/v1/accounts/${accountId}`,
      name: accountId,
    },
    item: selectedItem._links.self,
    routingRequest: routingRequestLink,
  };

  if (fulfillmentOptionsLink && !inputState.retrieveFulfillmentOptions) {
    (links as any).fulfillmentOptions = fulfillmentOptionsLink;
  }

  const requestBody: RouteV0RequestBody = {
    routingConfiguration: normalizedConfiguration,
    metadata: {
      // These will be validated before being used, so we know that orderedSkuCode can't be undefined.
      orderItems: orderItems as ({ orderedSkuCode: string } & OrderItem)[],
    },
    test: !inputState.persistResults,
    hidePrices: true,
    includeCurrentInfo: !inputState.ignoreRealTimeFactors,
    _links: links,
  };

  if (!_.isEmpty(overrideValues)) {
    (requestBody as any).overrideValues = overrideValues;
  }

  return requestBody;
}
