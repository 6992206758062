import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Alert, Tab, TabCard } from '@cimpress/react-components/';

import { setError } from '../features/alerts/alertContentSlice';
import { clearEvaluationResults } from '../features/evaluationResults/evaluationResultsSlice';
import { selectBuilderTab, selectTabByIndex, selectTesterTab } from '../features/tabs/tabSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import EditRoutingConfigurationPage from './EditRoutingConfigurationPage';
import ConfigurationTesterContainer from './configurationTester/ConfigurationTesterContainer';
import styles from './tabContainer.module.scss';

export default function TabContainer() {
  const navigate = useNavigate();
  const { id, accountId } = useParams();
  const { pathname } = useLocation();

  // These are in Redux state so that we can navigate the user
  // directly to one tab or another depending on where they're coming from
  const selectedTabIndex = useAppSelector((state) => state.tab.selectedTabIndex);

  const dispatch = useAppDispatch();
  const setSelectedIndex = (index: number) => dispatch(selectTabByIndex(index));
  const selectedRoutingConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  );

  const selectedSkuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);
  const dispatchErrors = ({ messages, title }: { messages: string[]; title: string }) =>
    dispatch(setError({ messages, title }));

  const tabs: Tab[] = [
    {
      name: 'Route Builder',
      block: <EditRoutingConfigurationPage />,
    },
    {
      name: 'Route Tester',
      block: <ConfigurationTesterContainer />,
    },
  ];

  const navigateOnTabSelect = (event, tabIndex: number) => {
    setSelectedIndex(tabIndex);
    if (tabIndex === 0) {
      dispatch(clearEvaluationResults());
      if (selectedRoutingConfiguration?.id && selectedRoutingConfiguration.id !== 'default') {
        navigate(`/accounts/${accountId}/configurations/edit/${selectedRoutingConfiguration.id}`);
      } else if (selectedSkuCode) {
        navigate(`/accounts/${accountId}/configurations/create?skuCode=${selectedSkuCode}`);
      } else {
        dispatchErrors({
          title: 'Navigation Error',
          messages: ['Not enough information; did you use an incomplete URL?'],
        });
        // Not enough information; navigate back to search page
        navigate(`/accounts/${accountId}/configurations`);
      }
    } else {
      navigate(`/accounts/${accountId}/configurations/evaluate`);
    }
  };

  let renderOutput: React.ReactNode;

  // Navigation directly to the testing tab
  if (!id && !selectedRoutingConfiguration && pathname.includes('evaluate')) {
    renderOutput = <ConfigurationTesterContainer />;
    dispatch(selectTesterTab());
  } else if (id === 'default' && (!accountId || !selectedSkuCode)) {
    dispatch(selectBuilderTab());
    renderOutput = (
      <div>
        <Alert
          title={'Routing configuration is read-only'}
          className={styles.alert}
          message={
            'This is a default configuration used when a buyer/SKU combination has not had a ' +
            'routing configuration created for it. To edit, ' +
            'please log in and create a routing configuration for this SKU.'
          }
          status="warning"
          dismissible
        />
        <EditRoutingConfigurationPage />;
      </div>
    );
  } else {
    // Tabbing over when we already have a routing configuration
    renderOutput = (
      <TabCard
        tabs={tabs}
        className={styles.container}
        selectedIndex={selectedTabIndex}
        onSelect={navigateOnTabSelect}
      />
    );
  }

  return renderOutput;
}
