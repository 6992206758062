import { LinkWithName } from '../types';
import fetchWithAuth from '../utils/fetchWithAuth';

export type Decision = {
  chosenOptionId: string;
  decisionId: string;
  itemId: string;
  strategyUrl: string;
  consideredOptions?: any[];
  selectedOption?: any;
  consideredFulfillmentConfigurationOptions?: any[];
  _links: {
    routingRequest: LinkWithName;
    itemRoutingRequest: LinkWithName;
    item: LinkWithName;
    self: LinkWithName;
    routingConfiguration: LinkWithName;
    fulfillmentOptions: LinkWithName;
  };
};

export const getRoutingDecision = async (url: string): Promise<Decision> => {
  return await fetchWithAuth<Decision>({
    endpointUrl: url,
    method: 'GET',
  });
};
