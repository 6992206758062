/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React from 'react';

import { colors } from '@cimpress/react-components';

import { CONDITION_CONFIGURATIONS } from '../../constants/conditionConfigurations';
import { OPERATOR_SELECTION_VALUES } from '../../constants/operatorSelectionValues';
import { DEFAULT_INPUT_HEIGHT, SMALL_FONT } from '../../constants/stylingConstants';
import {
  ComparisonValueOperator,
  ConditionConfiguration,
  DisplayValue,
  FactIdentifiers,
  IfThenRuleConditionWithId,
  Operator,
  SelectionSource,
} from '../../types';
import { getConditionConfiguration } from '../../utils/conversions';
import { isValidItemPath } from '../../utils/isValidPath';
import {
  getRequiredInputStatus,
  getRequiredInputStatusOnMaybeFalseValue,
} from '../../utils/validation';
import Geocode from '../geocode/GeocodeComponent';
import IfThenCheckTable from '../ifThenCheckValue/ifThenCheckTable';
import PathTextField from '../pathInput/PathTextField';
import SourceSelect from '../pathInput/SourceSelect';
import RoutedToComponent from '../routedTo/RoutedToComponent';
import OperatorSelect from '../styledComponents/OperatorSelect';
import ParameterSelect from '../styledComponents/ParameterSelect';
import StyledArrayInputField from '../styledComponents/StyledArrayInputField';
import StyledTextField from '../styledComponents/StyledTextField';
import Tip from '../styledComponents/Tip';
import TrashIconButton from '../styledComponents/TrashIconButton';
import ValueDropdownSelect from '../styledComponents/ValueDropdownSelect';
import { getConditionGridStyles } from './getConditionGridStyles';

const multiOperators = [Operator.in, Operator.notIn];

export default function Condition({
  condition,
  onConditionUpdate,
  ifThenIdsWithinNode,
  onFocus,
  focusedId,
  onDelete,
  parentKey,
  index,
  merchants,
}: {
  condition: IfThenRuleConditionWithId;
  onConditionUpdate: (condition: IfThenRuleConditionWithId) => void;
  ifThenIdsWithinNode: string[];
  onFocus: (id: string | null) => void;
  focusedId: string | null;
  onDelete: () => void;
  parentKey: string;
  index: number;
  merchants?: Record<string, DisplayValue<string>>;
}) {
  const {
    allowedOperators: operators,
    allowedValues,
    requiresTextInput,
    requiresArrayTextInput,
    inputType,
    parameterIdentifier: attributeValue,
    parameterLabel: label,
    comparisonValue,
    hideOperatorSelect,
    hideSpanContainingIs,
    disableOperatorSelect,
    valueLabel,
    lockValueSelectWith,
    fact,
    selectionSource,
  } = getConditionConfiguration(condition);

  const autoFocusId = `${condition.id}-value`;
  const customPathAutofocusId = `${condition.id}-custom-path`;

  const selectableDropdownValues = _.map(allowedValues, (v) => ({ label: `${v}`, value: v }));

  const updateParentWithChanges = (updates: Record<string, any>) => {
    onConditionUpdate({
      ...condition,
      ...updates,
    });
  };

  const handleSelectParameter = ({ value: conditionConfig }: { value: ConditionConfiguration }) => {
    updateParentWithChanges({
      fact: conditionConfig?.fact ?? '',
      path: conditionConfig?.path ?? '',
      value: initialValueForNewParameter(condition, conditionConfig) ?? '',
      operator: initialOperatorForNewParameter(conditionConfig),
      // Lodash's merge function will not overwrite a value with undefined;
      // hence, using null instead
      customSource: conditionConfig.parameterIdentifier === 'custom' ? 'item' : null,
      customPath: conditionConfig.parameterIdentifier === 'custom' ? '' : null,
    });
  };

  const handleSelectOperator = ({ value }: ComparisonValueOperator) => {
    updateParentWithChanges({
      operator: value,
      value: comparisonValue,
    });
  };

  const initialOperatorForNewParameter = (conditionConfig: ConditionConfiguration) => {
    if (condition.operator && _.includes(conditionConfig.allowedOperators, condition.operator)) {
      return condition.operator;
    }

    return conditionConfig.defaultOperator;
  };

  const handleSourceSelect = ({ value }: { value: string }) => {
    updateParentWithChanges({
      customSource: value,
    });
  };

  const handlePathChange = (customPath: string) => {
    updateParentWithChanges({ customPath });
  };

  const handleSelectValue = ({ value }) => updateParentWithChanges({ value });

  const handleRawValue = (value: any) => updateParentWithChanges({ value });

  const onUserInputIntoTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateParentWithChanges({ value: e.target.value });
  };

  const onUserInputIntoSelectableField =
    (isArray: boolean) => (e: DisplayValue<string>[] | DisplayValue<string>) => {
      if (isArray) {
        const _e = e as DisplayValue<string>[];
        updateParentWithChanges({
          value: _.map(_e, 'value'),
        });
      } else {
        const _e = e as DisplayValue<string>;
        updateParentWithChanges({
          value: _e?.value,
        });
      }
    };

  const getSelectionOptions = (_selectionSource: SelectionSource): DisplayValue<any>[] => {
    switch (_selectionSource) {
      case 'merchants':
        return merchants ? Object.values(merchants) : [];
      default:
        return [];
    }
  };

  let valueSelect: React.ReactNode;
  if (lockValueSelectWith !== undefined) {
    valueSelect = (
      <div css={valueStyles}>
        <ValueDropdownSelect
          label="Value"
          value={{ label: `${lockValueSelectWith} for`, value: lockValueSelectWith }}
          options={[]}
          onChange={handleSelectValue}
          required
          isDisabled
        />
      </div>
    );
  } else if (condition.fact === FactIdentifiers.successfulIfThens) {
    valueSelect = (
      <IfThenCheckTable
        conditionId={condition.id}
        selectedIfThenIds={comparisonValue}
        ifThenIdsWithinNode={ifThenIdsWithinNode}
        onChange={handleRawValue}
      />
    );
  } else if (attributeValue === 'routedTo') {
    valueSelect = (
      <RoutedToComponent
        conditionId={condition.id}
        routedToValue={comparisonValue}
        focusedId={focusedId}
        onFocus={onFocus}
        onUpdate={handleRawValue}
      />
    );
  } else if (attributeValue === 'geocode') {
    valueSelect = (
      <Geocode
        value={comparisonValue}
        conditionId={condition.id}
        onUpdate={handleRawValue}
        focusedId={focusedId}
        onFocus={onFocus}
      />
    );
  } else if (requiresArrayTextInput) {
    const isMulti = !!condition.operator && multiOperators.includes(condition.operator);

    let valueToShow;
    if (isMulti) {
      if (comparisonValue) {
        if (_.isArray(comparisonValue)) {
          valueToShow = comparisonValue.map((val) => ({ label: val, value: val }));
        } else {
          valueToShow = [comparisonValue].map((val) => ({ label: val, value: val }));
          onUserInputIntoSelectableField(isMulti)(valueToShow);
        }
      } else {
        valueToShow = [];
      }
    } else {
      if (comparisonValue) {
        if (_.isArray(comparisonValue)) {
          if (comparisonValue.length) {
            valueToShow = { label: comparisonValue[0], value: comparisonValue[0] };
            onUserInputIntoSelectableField(isMulti)(valueToShow);
          }
        } else {
          valueToShow = { label: comparisonValue, value: comparisonValue };
        }
      }
    }
    if (selectionSource) {
      valueSelect = (
        <div css={valueStyles}>
          <ValueDropdownSelect
            label={valueLabel}
            name="userInput"
            size="xs"
            value={valueToShow}
            onChange={onUserInputIntoSelectableField(isMulti)}
            type={inputType as any}
            onFocus={() => onFocus(autoFocusId)}
            autoFocus={focusedId === autoFocusId}
            status={getRequiredInputStatusOnMaybeFalseValue(valueToShow)}
            options={getSelectionOptions(selectionSource)}
            isMulti={isMulti}
            isClearable
            required
          />
        </div>
      );
    } else {
      valueSelect = (
        <div css={valueStyles}>
          <StyledArrayInputField
            size="xs"
            name="userInput"
            value={valueToShow}
            label={valueLabel}
            showDropdown={false}
            required
            onChange={onUserInputIntoSelectableField(isMulti)}
            isMulti={isMulti}
            type={inputType as any}
            onFocus={() => onFocus(autoFocusId)}
            autoFocus={focusedId === autoFocusId}
            onBlur={() => onFocus(null)}
            status={getRequiredInputStatusOnMaybeFalseValue(valueToShow)}
          />
        </div>
      );
    }
  } else if (requiresTextInput) {
    valueSelect = (
      <div css={valueStyles}>
        <StyledTextField
          size="xs"
          name="userInput"
          value={comparisonValue}
          label={valueLabel}
          required
          onChange={onUserInputIntoTextField}
          type={inputType as any}
          onFocus={() => onFocus(autoFocusId)}
          autoFocus={focusedId === autoFocusId}
          onBlur={() => onFocus(null)}
          status={getRequiredInputStatusOnMaybeFalseValue(comparisonValue)}
        />
      </div>
    );
  } else {
    let maybeEmptyValue: DisplayValue<any> | undefined;
    if (comparisonValue === null || comparisonValue === undefined) {
      maybeEmptyValue = undefined;
    } else {
      maybeEmptyValue = { label: `${comparisonValue}`, value: comparisonValue };
    }

    valueSelect = (
      <div css={valueStyles}>
        <ValueDropdownSelect
          label="Value"
          value={maybeEmptyValue}
          options={selectableDropdownValues}
          onChange={handleSelectValue}
          required
          status={getRequiredInputStatusOnMaybeFalseValue(comparisonValue)}
        />
      </div>
    );
  }

  const gridStyles = getConditionGridStyles({
    fact,
    attributeValue,
    condition,
  });

  const parameterValue = fact === undefined ? undefined : { label, value: attributeValue };
  return (
    <div key={`wrapper-${parentKey}-${index}`} css={gridStyles}>
      <ParameterSelect
        label="Parameter"
        value={parameterValue}
        options={parameterOptions}
        onChange={handleSelectParameter}
        required
        status={getRequiredInputStatus(attributeValue)}
      />

      {!hideSpanContainingIs && (
        <div css={isStyles}>
          <span>IS</span>
        </div>
      )}
      {!hideOperatorSelect && (
        <OperatorSelect
          label="Comparison"
          value={OPERATOR_SELECTION_VALUES[condition.operator ?? '']}
          options={_.map(operators, (op) => OPERATOR_SELECTION_VALUES[op])}
          onChange={handleSelectOperator}
          required
          status={getRequiredInputStatus(condition.operator)}
          isDisabled={disableOperatorSelect}
        />
      )}

      {condition.customSource && (
        <>
          <SourceSelect
            value={condition.customSource}
            onChange={handleSourceSelect}
            required
            status={condition.customSource ? 'success' : 'error'}
          />
          <PathTextField
            value={condition.customPath!}
            onChange={(path: string) => handlePathChange(path)}
            onFocus={() => onFocus(customPathAutofocusId)}
            autoFocus={focusedId === customPathAutofocusId}
            onBlur={() => onFocus(null)}
            status={isValidItemPath(condition.customPath) ? 'success' : 'error'}
            required
          />
        </>
      )}

      {valueSelect}

      <TrashIconButton onDelete={onDelete} />

      {/* TODO: This is a lie, nothing parses the comma separated strings! They all expect arrays */}
      {!selectionSource &&
        (condition.operator === Operator.in || condition.operator === Operator.notIn) && (
          <Tip>You can enter multiple values separated by commas</Tip>
        )}

      {condition.path === CONDITION_CONFIGURATIONS.cmrd.path && (
        <Tip>
          This refers to the number of working days from the current date until the
          localPromisedArrivalDate, not including weekends. For example, if today is Monday & the
          localPromisedArrivalDate is Thursday, that is 3 days.
        </Tip>
      )}
    </div>
  );
}

const parameterOptions = _.reduce(
  CONDITION_CONFIGURATIONS,
  (array, conditionConfig) => {
    // Skip the blank one
    if (conditionConfig.parameterIdentifier) {
      array.push({ label: conditionConfig.parameterLabel, value: conditionConfig });
    }
    return array;
  },
  [] as { label: string; value: ConditionConfiguration }[],
);

const initialValueForNewParameter = (
  condition: IfThenRuleConditionWithId,
  conditionConfig: ConditionConfiguration,
) => {
  // For boolean choices, just pick one; we might save some clicks
  if (conditionConfig?.allowedValues?.length === 2) {
    return conditionConfig.allowedValues[0];
  }

  // If the value select is locked, then pick the default value.
  // Note we need to check for undefined because "false" is
  // an acceptable value.
  if (conditionConfig.lockValueSelectWith !== undefined) {
    return conditionConfig.defaultValue;
  }

  // If the new parameter requires text input, and we have text, just keep it
  if (conditionConfig.requiresTextInput) {
    if (conditionConfig.inputType === 'text') {
      return typeof condition.value === 'string' ? condition.value : conditionConfig.defaultValue;
    }
    if (conditionConfig.inputType === 'number') {
      return _.isNaN(Number(condition.value))
        ? conditionConfig.defaultValue
        : Number(condition.value);
    }
  }

  return conditionConfig.defaultValue;
};

const isStyles = css`
  align-items: center;
  color: ${colors.shale};
  display: flex;
  grid-area: is;
  font-size: ${SMALL_FONT};
  height: ${DEFAULT_INPUT_HEIGHT};
  margin: 0 auto;
`;

const valueStyles = css`
  grid-area: value;
`;
