export function scrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export function scrollToBottom() {
  const scrollHeight = document.documentElement.scrollHeight;
  const clientHeight = document.documentElement.clientHeight;
  window.scrollTo({
    top: scrollHeight - clientHeight,
    left: 0,
    behavior: 'smooth',
  });
}
