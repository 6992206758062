import React from 'react';

import { Button, Modal } from '@cimpress/react-components';

import styles from './whatToExpectModal.module.scss';

export default function WhatToExpectModal({ show, close }: { show: boolean; close: () => void }) {
  return (
    <Modal
      className={styles.modal}
      show={show}
      closeButton={true}
      onRequestHide={close}
      title="What to Expect From The Routing Tester"
      footer={<Button onClick={close}>Okay</Button>}
    >
      <p>
        The Routing Tester allows you to use an existing order (whether a production or test order)
        to rapidly iterate tests on a Routing Configuration that you are editing. Used in this way,
        there is no need to create a new test order for each change to a Routing Configuration.
      </p>
      <p>
        Many enhancements are planned for the Routing Tester in the near future. Below are some
        important limitations in the current version of the Routing Tester.
      </p>
      <ul>
        <li className={styles.listItem}>
          An item must be selected from an existing order in order to fetch fulfillment options from
          that decision; this means you cannot use the Routing Tester for a skuCode that has not
          been ordered yet
        </li>
        <li className={styles.listItem}>Pickup points are not yet supported</li>
        <li className={styles.listItem}>
          Other order items (i.e. to test routing that depends on if another item was ordered or how
          another item was routed) is not yet supported
        </li>
        <p className={styles.modalParagraph}>
          These limitations may be partially or completely removed as new features get added.
        </p>
      </ul>
    </Modal>
  );
}
