import _ from 'lodash';
import { useEffect, useState } from 'react';

import { DisplayValue } from '../types';
import { getMerchants } from '../utils/getMerchants';

export function useMerchants() {
  const [merchantsData, setMerchantsData] = useState<Record<string, DisplayValue<string>>>({});

  useEffect(() => {
    const fetchMerchants = async () => {
      const _merchants = await getMerchants();
      const data = _(_merchants)
        .entries()
        .reduce(
          (accumulator, [name, { merchantId }]) => {
            accumulator[name] = { label: name, value: merchantId };
            return accumulator;
          },
          {} as Record<string, DisplayValue<string>>,
        );

      setMerchantsData(data);
    };

    if (_.isEmpty(merchantsData)) {
      fetchMerchants();
    }
  }, []);

  return merchantsData;
}
