import { ConditionConfiguration, FactIdentifiers, Operator } from '../types';

const allOptionOperators = [Operator.allAre, Operator.anyAre, Operator.noneAre];

const numericOperators = [
  Operator.equal,
  Operator.notEqual,
  Operator.greaterThan,
  Operator.greaterThanInclusive,
  Operator.lessThan,
  Operator.lessThanInclusive,
];

const numericOperatorsWithListValues = [...numericOperators, Operator.in, Operator.notIn];

const textOperators = [Operator.equal, Operator.notEqual, Operator.in, Operator.notIn];

const anyBaseOperator = [
  Operator.equal,
  Operator.notEqual,
  Operator.greaterThan,
  Operator.greaterThanInclusive,
  Operator.lessThan,
  Operator.lessThanInclusive,
  Operator.in,
  Operator.notIn,
  Operator.dateLess,
  Operator.dateLessEqual,
  Operator.dateGreater,
  Operator.dateGreaterEqual,
  Operator.containsNone,
  // Not included:
  // all/any/noneAre,
  // poBox,
  // alsoRouted,
  // orderedWithAnyOf
];

export const CONDITION_CONFIGURATIONS: Record<string, ConditionConfiguration> = {
  blank: {
    allowedOperators: [],
    parameterIdentifier: '',
    parameterLabel: '',
    path: '',
    fact: '',
    allowedValues: [],
    requiresTextInput: false,
    valueLabel: 'Value',
    defaultValue: '',
  },
  quantity: {
    allowedOperators: numericOperators,
    defaultOperator: Operator.equal,
    parameterIdentifier: 'quantity',
    parameterLabel: 'Quantity',
    path: 'item.quantity',
    fact: FactIdentifiers.orderContext,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'number',
    valueLabel: 'Integer',
    shouldTruncateNumberValue: true,
    defaultValue: 0,
  },
  postalCode: {
    allowedOperators: numericOperatorsWithListValues,
    defaultOperator: Operator.equal,
    parameterIdentifier: 'postalCode',
    parameterLabel: 'Postal Code',
    path: 'item.deliveryRequest.destinationAddress.postalCode',
    fact: FactIdentifiers.orderContext,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'text',
    requiresArrayTextInput: true,
    valueLabel: 'Postal code',
  },
  poBox: {
    allowedOperators: [Operator.poBox],
    defaultOperator: Operator.poBox,
    defaultValue: false,
    allowedValues: [true, false],
    parameterIdentifier: 'poBox',
    parameterLabel: 'Destination address',
    path: 'item.deliveryRequest.destinationAddress',
    fact: FactIdentifiers.orderContext,
    disableOperatorSelect: true,
    requiresTextInput: false,
  },
  geocode: {
    allowedOperators: [Operator.geocode],
    defaultOperator: Operator.geocode,
    allowedValues: [],
    parameterIdentifier: 'geocode',
    parameterLabel: 'Geocode',
    path: 'item.deliveryRequest.destinationAddress',
    fact: FactIdentifiers.orderContext,
    requiresTextInput: true,
    inputType: 'text',
    hideOperatorSelect: true,
    hideSpanContainingIs: true,
    defaultValue: [{ country: '' }],
  },
  orderedWith: {
    allowedOperators: [Operator.orderedWithAnyOf],
    defaultOperator: Operator.orderedWithAnyOf,
    fact: FactIdentifiers.orderContext,
    path: 'metadata.orderItems',
    parameterIdentifier: 'orderedWith',
    allowedValues: [],
    requiresArrayTextInput: true,
    requiresTextInput: true,
    disableOperatorSelect: true,
    inputType: 'text',
    parameterLabel: 'Item ordered with',
    valueLabel: 'Skucode',
  },
  routedTo: {
    allowedOperators: [Operator.routedTo],
    defaultOperator: Operator.routedTo,
    fact: FactIdentifiers.orderContext,
    path: 'routedTo',
    parameterIdentifier: 'routedTo',
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'text',
    parameterLabel: 'Routed To (Also Routes)',
    hideOperatorSelect: true,
    hideSpanContainingIs: true,
    defaultValue: {
      resultType: '',
      resultValue: '',
    },
  },
  merchant: {
    allowedOperators: textOperators,
    defaultOperator: Operator.equal,
    path: 'item.merchantId',
    fact: FactIdentifiers.orderContext,
    allowedValues: [],
    requiresArrayTextInput: true,
    requiresTextInput: true,
    inputType: 'text',
    parameterLabel: 'Merchant',
    parameterIdentifier: 'merchant',
    selectionSource: 'merchants',
    valueLabel: 'Merchant ID',
  },
  cmrd: {
    allowedOperators: numericOperators,
    defaultOperator: Operator.equal,
    parameterIdentifier: 'promisedArrivalDate',
    parameterLabel: 'Promised arrival date',
    path: 'item.localPromisedArrivalDate',
    fact: FactIdentifiers.orderContext,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'number',
    valueLabel: 'Business days',
    shouldTruncateNumberValue: true,
  },
  itemCount: {
    allowedOperators: numericOperators,
    defaultOperator: Operator.equal,
    parameterIdentifier: 'itemCount',
    parameterLabel: 'Item Count',
    path: 'metadata.orderItems.length',
    fact: FactIdentifiers.orderContext,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'number',
    valueLabel: '# of unique items',
    shouldTruncateNumberValue: true,
  },
  skuCount: {
    allowedOperators: numericOperators,
    defaultOperator: Operator.equal,
    parameterIdentifier: 'skuCount',
    parameterLabel: 'SKU Count',
    path: 'metadata.skuCounts.length',
    fact: FactIdentifiers.orderContext,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'number',
    defaultValue: '',
    valueLabel: '# of unique skus',
    shouldTruncateNumberValue: true,
  },
  optionsAreLate: {
    allowedOperators: allOptionOperators,
    defaultOperator: Operator.allAre,
    parameterIdentifier: 'late',
    parameterLabel: 'Options are late',
    path: '',
    fact: FactIdentifiers.options,
    allowedValues: [],
    requiresTextInput: false,
    lockValueSelectWith: true,
    defaultValue: {
      path: '_embedded.deliveryFeasibility.lateArrival',
      filterPath: undefined,
      filterValue: undefined,
    },
  },
  optionsAreValid: {
    allowedOperators: allOptionOperators,
    defaultOperator: Operator.allAre,
    parameterIdentifier: 'valid',
    parameterLabel: 'Options are valid',
    path: '',
    fact: FactIdentifiers.options,
    allowedValues: [],
    requiresTextInput: false,
    lockValueSelectWith: true,
    defaultValue: {
      path: 'isValid',
      filterPath: undefined,
      filterValue: undefined,
    },
  },
  custom: {
    allowedOperators: anyBaseOperator,
    defaultOperator: Operator.equal,
    parameterIdentifier: 'custom',
    parameterLabel: 'Custom Attribute',
    path: '',
    fact: FactIdentifiers.orderContext,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'text',
    valueLabel: 'Value',
    defaultValue: '',
  },
  successfulIfThens: {
    allowedOperators: [Operator.containsNone],
    defaultOperator: Operator.containsNone,
    parameterIdentifier: 'successfulIfThens',
    parameterLabel: 'Passing Condition Groups',
    path: '',
    fact: FactIdentifiers.successfulIfThens,
    allowedValues: [],
    requiresTextInput: false,
    valueLabel: 'Condition Group',
    defaultValue: [],
    disableOperatorSelect: true,
    hideSpanContainingIs: true,
  },
};
