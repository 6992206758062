import { configureStore } from '@reduxjs/toolkit';

import alertContentReducer from '../features/alerts/alertContentSlice';
import evaluationReducer from '../features/evaluationResults/evaluationResultsSlice';
import globalSettingsReducer from '../features/globalSettings/globalSettingsSlice';
import productConfigurationReducer from '../features/productConfigurationSearch/productConfigurationSlice';
import resourceSearchReducer from '../features/resourceSearch/resourceSearchSlice';
import selectedConfigurationReducer from '../features/selectedConfiguration/selectedConfigurationSlice';
import tabSliceReducer from '../features/tabs/tabSlice';
import testInputReducer from '../features/testInput/testInputSlice';

export const store = configureStore({
  reducer: {
    testInput: testInputReducer,
    selectedConfiguration: selectedConfigurationReducer,
    productConfiguration: productConfigurationReducer,
    resourceSearch: resourceSearchReducer,
    globalSettings: globalSettingsReducer,
    alertContent: alertContentReducer,
    evaluationResults: evaluationReducer,
    tab: tabSliceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
