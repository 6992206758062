import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  RouteV0RequestBody,
  RoutingResult,
  evaluateRoutingConfiguration,
} from '../../services/testRoutingDecision';

export const runRoutingEvaluation = createAsyncThunk(
  'selectedConfiguration/runRoutingEvaluation',
  async (args: RouteV0RequestBody) => {
    return await evaluateRoutingConfiguration(args);
  },
);

interface EvaluationResultsState {
  results?: RoutingResult;
  loading: boolean;
}

const EvaluationResultsSlice = createSlice({
  name: 'testResults',
  initialState: { loading: false } as EvaluationResultsState,
  reducers: {
    clearEvaluationResults: (state) => {
      state.results = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(runRoutingEvaluation.fulfilled, (state, action) => {
      state.results = action.payload;
      state.loading = false;
    });
    builder.addCase(runRoutingEvaluation.pending, (state) => {
      state.loading = true;
      state.results = undefined;
    });
    builder.addCase(runRoutingEvaluation.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default EvaluationResultsSlice.reducer;

export const { clearEvaluationResults } = EvaluationResultsSlice.actions;
