import React from 'react';

import { AppAlert } from '../AppAlert';
import FormContainer from './FormContainer';
import TestResults from './TestResults';
import styles from './configurationTesterContainer.module.scss';

export default function ConfigurationTesterContainer() {
  return (
    <div>
      <AppAlert />
      <div className={styles.container}>
        <FormContainer />
        <TestResults />
      </div>
    </div>
  );
}
