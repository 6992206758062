/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { memo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Button, colors } from '@cimpress/react-components';

import { DEFAULT_INPUT_HEIGHT, SMALL_FONT } from '../../constants/stylingConstants';
import {
  Action,
  DisplayValue,
  IfThenRuleConditionWithId,
  IfThenWithConditionIds,
} from '../../types';
import { getFactFromIfThenRuleCondition } from '../../utils/conversions';
import { parseConditionJoiner } from '../../utils/parseConditionJoiner';
import AddElementButton from '../AddElementButton';
import NextNodeWrapperTooltip from '../NextNodeWrapperTooltip';
import ActionSelection from '../actionSelection/ActionSelection';
import ConditionGroupNextNodeSelect from '../styledComponents/ConditionGroupNextNodeSelect';
import HorizontalRule from '../styledComponents/HorizontalRule';
import NothingConfigured from '../styledComponents/NothingConfigured';
import StyledTextField from '../styledComponents/StyledTextField';
import Condition from './Condition';
import ConditionJoiner from './ConditionJoiner';

type ConditionGroupProps = {
  updateIfThen: (updatedIfThen: IfThenWithConditionIds) => void;
  deleteIfThen: () => void;
  nodeOptions: DisplayValue<string>[];
  ifThen: IfThenWithConditionIds;
  ifThenIdsWithinNode: string[];
  handleInputFocus: (s: string | null) => void;
  focusedInput: string | null;
  fulfillers?: Record<string, DisplayValue<string>>;
  fulfillmentExpectations?: Record<string, DisplayValue<string>>;
  merchants?: Record<string, DisplayValue<string>>;
};

// corresponds to a single IfThen
export default memo(function ConditionGroup({
  updateIfThen,
  deleteIfThen,
  nodeOptions,
  ifThen,
  ifThenIdsWithinNode,
  handleInputFocus,
  focusedInput,
  fulfillers,
  merchants,
  fulfillmentExpectations,
}: ConditionGroupProps) {
  const [key, setKey] = useState<string>(uuid());
  const [conditionJoiner, setConditionJoiner] = useState<'all' | 'any'>(
    parseConditionJoiner(ifThen),
  );

  const nextNode = !_.isEmpty(ifThen.nextNodeId)
    ? _.find(nodeOptions, (node) => node.value === ifThen.nextNodeId)
    : undefined;

  const handleSelectNextNode = (nextNodeSelection?: DisplayValue<string>) => {
    const nextNodeWithId: DisplayValue<string> | undefined = nextNodeSelection?.value
      ? _.find(nodeOptions, (opt) => opt.value === nextNodeSelection.value)
      : undefined;
    const ifThenClone = { ...ifThen };
    ifThenClone.nextNodeId = nextNodeWithId?.value;
    updateIfThen(ifThenClone);
  };

  const addCondition = () => {
    const ifThenClone = { ...ifThen };
    if (!ifThenClone.rule.conditions[conditionJoiner]?.length) {
      ifThenClone.rule.conditions[conditionJoiner] = [];
    }
    ifThenClone.rule.conditions[conditionJoiner]!.push({ id: uuid(), value: '' } as any);
    updateIfThen(ifThenClone);
  };

  const deleteCondition = (index: number) => {
    const ifThenClone = { ...ifThen };
    ifThenClone.rule.conditions[conditionJoiner]!.splice(index, 1);
    updateIfThen(ifThenClone);
  };

  const handleConditionChange = (updatedCondition: IfThenRuleConditionWithId, index: number) => {
    const fact = getFactFromIfThenRuleCondition(updatedCondition);
    updatedCondition.fact = fact ?? updatedCondition.fact;

    const ifThenClone = { ...ifThen };
    ifThenClone.rule.conditions[conditionJoiner]![index] = updatedCondition;
    updateIfThen(ifThenClone);
    setKey(uuid());
  };

  const handleConditionJoinerChange = (joiner: 'all' | 'any') => {
    const conditions = ifThen.rule.conditions[conditionJoiner]!;
    delete ifThen.rule.conditions[conditionJoiner];
    ifThen.rule.conditions[joiner] = conditions;
    setConditionJoiner(joiner);
    updateIfThen(ifThen);
    setKey(uuid());
  };

  const handleIfThenNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateIfThen({
      ...ifThen,
      name: e.target.value,
    });
  };

  const handleIfThenDescriptionChange = (e: any) => {
    updateIfThen({
      ...ifThen,
      description: e.target.value,
    });
  };

  const handleActionUpdate = (updatedAction: Action) => {
    if (updatedAction) {
      updateIfThen({
        ...ifThen,
        actionId: updatedAction.id,
        action: updatedAction,
      });
    }
  };

  const handleActionDelete = () => {
    updateIfThen({
      ...ifThen,
      actionId: undefined,
      action: undefined,
    });
  };

  const conditions = _.map(
    ifThen.rule.conditions[conditionJoiner]!,
    (condition: IfThenRuleConditionWithId, index: number) => (
      <div key={`condition-${key}-${index}`}>
        {index > 0 && (
          <div>
            <ConditionJoiner
              joiner={conditionJoiner}
              setJoiner={handleConditionJoinerChange}
              enabled={index === 1}
            />
          </div>
        )}
        <Condition
          key={`condition-${key}-${index}`}
          parentKey={`condition-${key}-${index}`}
          index={index}
          condition={condition}
          ifThenIdsWithinNode={ifThenIdsWithinNode}
          onConditionUpdate={(updatedCondition) => handleConditionChange(updatedCondition, index)}
          onDelete={() => deleteCondition(index)}
          onFocus={handleInputFocus}
          focusedId={focusedInput}
          merchants={merchants}
        />
      </div>
    ),
  );

  return (
    <div css={conditionGroupStyles} key={ifThen.id}>
      <div css={flexDivFlexStart}>
        <div>
          <StyledTextField
            name="ifThenName"
            value={ifThen.name}
            onChange={handleIfThenNameChange}
            label="Condition group name"
            onFocus={() => handleInputFocus(`ifThen-${ifThen.id}-name`)}
            autoFocus={focusedInput === `ifThen-${ifThen.id}-name`}
            onBlur={() => handleInputFocus(null)}
          />
        </div>
        <StyledTextField
          size="lg"
          name="ifThenDescription"
          value={ifThen.description}
          onChange={handleIfThenDescriptionChange}
          label="Description"
          onFocus={() => handleInputFocus(`ifThen-${ifThen.id}-description`)}
          autoFocus={focusedInput === `ifThen-${ifThen.id}-description`}
          onBlur={() => handleInputFocus(null)}
        />
      </div>
      <p css={leadInStyles}>IF...</p>
      {conditions}
      <AddElementButton onClick={addCondition} text="Add condition" />
      {!conditions?.length && (
        <NothingConfigured>
          This condition group has no conditions; it will automatically apply its action and pass
          options to the next node.
        </NothingConfigured>
      )}

      <HorizontalRule />

      <p css={thenStyles}>THEN...</p>
      <ActionSelection
        action={ifThen.action}
        onActionUpdate={(a) => handleActionUpdate(a)}
        onFocus={handleInputFocus}
        focusedInput={focusedInput}
        onDelete={handleActionDelete}
        fulfillers={fulfillers}
        fulfillmentExpectations={fulfillmentExpectations}
        notActionConfiguredText={`This condition group has no action configured; if it passes, 
        it will pass options to the next node.`}
      />

      <div css={nextNodeContainer}>
        <NextNodeWrapperTooltip enabled={!nodeOptions.length}>
          <ConditionGroupNextNodeSelect
            label="Next Node"
            value={nextNode}
            options={nodeOptions}
            onChange={handleSelectNextNode}
            isDisabled={!nodeOptions.length}
            isClearable
          />
        </NextNodeWrapperTooltip>
        <Button css={removeButtonStyles} onClick={deleteIfThen}>
          Remove Condition Group
        </Button>
      </div>
    </div>
  );
});

const flexDivFlexStart = css`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const leadInStyles = css`
  color: ${colors.shale};
  font-size: ${SMALL_FONT};
  margin-bottom: 4px;
`;

const conditionGroupStyles = css`
  border: 1px solid ${colors.slate};
  border-radius: 4px;
  padding: 12px;
  position: relative;
`;

const nextNodeContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (max-width: 600px) {
    // Make room for the remove condition group button
    margin-bottom: 40px;
  }
`;

const removeButtonStyles = css`
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: ${DEFAULT_INPUT_HEIGHT};
  font-size: 1.15em;
`;

const thenStyles = css`
  color: ${colors.shale};
  font-size: ${SMALL_FONT};
`;
