/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { RawNodeDatum } from 'react-d3-tree';
import { v4 as uuid } from 'uuid';

import { Button, TextField, colors } from '@cimpress/react-components';

import { useAppSelector } from '../../store/hooks';
import {
  SelectionStrategy,
  WorkingRoutingConfigurationNode,
  WorkingRoutingConfigurationV3,
} from '../../types';
import ConfigurationSettings from '../configurationSettings/ConfigurationSettings';
import SelectionStrategySelector from '../configurationSettings/SelectionStrategySelector';
import StyledTextField from '../styledComponents/StyledTextField';
import NodeTree from './NodeTree';
import ZoomButtons from './ZoomButtons';

const minZoom = 0.1;
const maxZoom = 1.5;
const defaultZoom = 1;

export default function NodeTreeContainer({
  onNodeClick,
  workingConfiguration,
  setWorkingConfiguration,
  publishButton,
  tree,
}: {
  onNodeClick: any;
  workingConfiguration: WorkingRoutingConfigurationV3;
  setWorkingConfiguration: (r: WorkingRoutingConfigurationV3) => void;
  publishButton: React.ReactNode;
  tree: RawNodeDatum;
}) {
  const nodeTreeRef = useRef(null);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [treeKey, setTreeKey] = useState(uuid());
  const [zoom, setZoom] = useState(defaultZoom);

  const skuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);

  const resetPosition = () => {
    if (nodeTreeRef.current) {
      const { width } = (nodeTreeRef.current as any).getBoundingClientRect();
      setTranslate({ x: width / 2, y: 25 });
      setZoom(defaultZoom);
      // Force the tree to rerender even if the bounds of the canvas haven't changed
      setTreeKey(uuid());
    }
  };

  useEffect(() => {
    resetPosition();
    // The RC will change as edits are made; ignore that.
    // However, if a different RC is selected, we should reset the canvas.
  }, [workingConfiguration?.id]);

  const addNode = () => {
    const newNode: WorkingRoutingConfigurationNode = {
      id: uuid(),
      name: `New Node ${workingConfiguration.nodes.length + 1}`,
    };

    const routingConfigurationWithEdits = _.cloneDeep(workingConfiguration);

    routingConfigurationWithEdits.nodes.push(newNode);
    setWorkingConfiguration(routingConfigurationWithEdits);
  };

  const updateSelectionStrategy = (strategy: string) => {
    setWorkingConfiguration({
      ...workingConfiguration,
      selectionStrategy: strategy as SelectionStrategy,
    });
  };

  const updateRoutingConfigDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkingConfiguration({
      ...workingConfiguration,
      description: e.target.value,
    });
  };

  return (
    <div>
      <div css={topSectionStyles}>
        <div css={configurationSettingStyles}>
          <ConfigurationSettings>
            <StyledTextField
              label="Description"
              onChange={updateRoutingConfigDescription}
              value={workingConfiguration?.description ?? ''}
              size="lg"
            />
            <SelectionStrategySelector
              selectionStrategy={workingConfiguration?.selectionStrategy}
              onUpdateSelectionStrategy={updateSelectionStrategy}
            />
            {skuCode && (
              <TextField
                css={skuCodeTextFieldStyles}
                value={`SKU: ${skuCode}`}
                onChange={() => {}}
                disabled
              />
            )}
            {workingConfiguration?.id && workingConfiguration.id !== 'default' && (
              <a
                // eslint-disable-next-line max-len
                href={`${process.env.REACT_APP_ROUTING_CONFIGURATION_URL}/v3/routingConfigurations/${workingConfiguration.id}`}
              >
                Configuration Resource
              </a>
            )}
          </ConfigurationSettings>
          <div css={zoomControlStyles}>
            <span css={infoSpanStyles}>
              Click and drag to move; mouse wheel or + and - buttons to zoom.
            </span>
            <ZoomButtons
              zoom={zoom}
              setZoom={setZoom}
              minZoom={minZoom}
              maxZoom={maxZoom}
              resetPosition={resetPosition}
            />
          </div>
        </div>
      </div>

      <NodeTree
        key={treeKey}
        ref={nodeTreeRef}
        onNodeClick={onNodeClick}
        workingConfiguration={workingConfiguration}
        setWorkingConfiguration={setWorkingConfiguration}
        translate={translate}
        tree={tree}
        zoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
      />

      <div css={footerStyles}>
        <Button css={newNodeButtonStyles} onClick={addNode}>
          Create New Node
        </Button>
        {publishButton}
      </div>
    </div>
  );
}

const zoomControlStyles = css`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: '. center right';

  @media (max-width: 1040px) {
    margin-left: 24px;
    grid-template-columns: auto auto;
    grid-template-areas: 'center right';
  }
`;

const skuCodeTextFieldStyles = css`
  width: 200px;
`;

const topSectionStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const configurationSettingStyles = css`
  width: 100%;
`;

const infoSpanStyles = css`
  color: ${colors.cobalt.base};
  font-style: italic;
  margin: auto 0 0 auto;
  grid-area: center;
`;

const newNodeButtonStyles = css`
  height: 48px;
  margin: 16px;
`;

const footerStyles = css`
  display: flex;
  justify-content: space-between;
  margin-left: 8px;
`;
