import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { OrderItem } from '../../components/configurationTester/inputs/OrderItems';
import { Item, ItemWithOrderMetadata } from '../../services/items';
import {
  getDecisionFromItem,
  searchExistingResources,
} from '../resourceSearch/resourceSearchSlice';

export interface TestInputState {
  quantity: number;
  merchantId: string;
  country: string | null;
  stateOrProvince: string | undefined;
  postalCode: string;
  isPoBox: boolean;
  fulfillmentCaps: string[];
  orderDate: string;
  promisedArrivalDate: string;
  overrideValues: string[];
  selectedItem?: ItemWithOrderMetadata;
  items: Item[];
  orderItems: OrderItem[];
  orderItemsAreValid: boolean;
  testInputIsValid: boolean;
  retrieveFulfillmentOptions: boolean;
  ignoreRealTimeFactors: boolean;
  persistResults: boolean;
  deliveryConstraints: {
    carrierServices?: string[];
    preferredCarrierServices?: string[];
    carrierServiceCapabilities?: string[];
  };
}

const initialState: TestInputState = {
  quantity: 1,
  merchantId: '',
  country: null,
  stateOrProvince: '',
  postalCode: '',
  orderDate: '',
  promisedArrivalDate: '',
  isPoBox: false,
  fulfillmentCaps: [],
  overrideValues: [],
  items: [],
  orderItems: [],
  orderItemsAreValid: false,
  testInputIsValid: false,
  retrieveFulfillmentOptions: false,
  ignoreRealTimeFactors: true,
  persistResults: false,
  deliveryConstraints: {},
};

const validateTestInput = (state: Draft<TestInputState>) => {
  const requiredFields = ['country', 'postalCode', 'promisedArrivalDate', 'items'];

  return _.every(requiredFields, (field) => !!state[field]);
};

const testInputSlice = createSlice({
  name: 'testInput',
  initialState,
  reducers: {
    setSelectedItem(state, action: PayloadAction<ItemWithOrderMetadata | undefined>) {
      const selectedItem = action.payload;
      state.selectedItem = selectedItem;

      state.merchantId = selectedItem?.merchantId || '';

      state.orderDate = _.get(selectedItem, 'statuses.platformAccepted.updatedDate', '');
      state.promisedArrivalDate = selectedItem?.localPromisedArrivalDate || '';

      const destinationAddress = selectedItem?.deliveryRequest?.destinationAddress;
      state.country = destinationAddress?.country || null;
      state.postalCode = destinationAddress?.postalCode || '';
      state.isPoBox = destinationAddress?.isPOBox || false;
      state.stateOrProvince = destinationAddress?.stateOrProvince || '';

      state.deliveryConstraints = selectedItem?.deliveryRequest?.deliveryConstraints || {};

      if (selectedItem?.fulfillmentCapabilities) {
        state.fulfillmentCaps = selectedItem?.fulfillmentCapabilities;
      }

      state.persistResults = false;
    },
    setOrderItemsAreValid(state, action: PayloadAction<boolean>) {
      state.orderItemsAreValid = action.payload;
    },
    setOrderItems(state, action: PayloadAction<OrderItem[]>) {
      state.orderItems = action.payload;
    },
    setOrderItemsFromRawItems(state, action: PayloadAction<ItemWithOrderMetadata[]>) {
      state.orderItems = _.map(action.payload, (item) => ({
        orderedSkuCode: item.orderedSkuCode || item.mcpSku,
        skuCode: item.mcpSku,
        fulfillerId: item.globalFulfillerId,
      }));
    },
    setFulfillmentCaps(state, action: PayloadAction<string[]>) {
      const newSet = new Set([...state.overrideValues, 'fulfillmentCaps']);
      state.overrideValues = Array.from(newSet);
      state.fulfillmentCaps = action.payload;
    },
    setOrderDate(state, action: PayloadAction<string>) {
      const newSet = new Set([...state.overrideValues, 'orderDate']);
      state.overrideValues = Array.from(newSet);
      state.orderDate = action.payload;
    },
    setPromisedArrivalDate(state, action: PayloadAction<string>) {
      const newSet = new Set([...state.overrideValues, 'promisedArrivalDate']);
      state.overrideValues = Array.from(newSet);
      state.promisedArrivalDate = action.payload;
    },
    setMerchantId(state, action: PayloadAction<string>) {
      const newSet = new Set([...state.overrideValues, 'merchantId']);
      state.overrideValues = Array.from(newSet);
      state.merchantId = action.payload;
    },
    setIsPoBox(state, action: PayloadAction<boolean>) {
      const newSet = new Set([...state.overrideValues, 'isPoBox']);
      state.overrideValues = Array.from(newSet);
      state.isPoBox = action.payload;
    },
    setCountry(state, action: PayloadAction<string | null>) {
      const newSet = new Set([...state.overrideValues, 'country']);
      state.overrideValues = Array.from(newSet);
      state.country = action.payload;
    },
    setPostalCode(state, action: PayloadAction<string>) {
      const newSet = new Set([...state.overrideValues, 'postalCode']);
      state.overrideValues = Array.from(newSet);
      state.postalCode = action.payload;
    },
    setStateOrProvince(state, action: PayloadAction<string>) {
      const newSet = new Set([...state.overrideValues, 'stateOrProvince']);
      state.overrideValues = Array.from(newSet);
      state.stateOrProvince = action.payload;
    },
    setDeliveryConstraintsCarrierServices(state, action: PayloadAction<string[]>) {
      const newSet = new Set([...state.overrideValues, 'deliveryConstraints']);
      state.overrideValues = Array.from(newSet);
      state.deliveryConstraints.carrierServices = action.payload;
    },
    setDeliveryConstraintsPreferredCarrierServices(state, action: PayloadAction<string[]>) {
      const newSet = new Set([...state.overrideValues, 'deliveryConstraints']);
      state.overrideValues = Array.from(newSet);
      state.deliveryConstraints.preferredCarrierServices = action.payload;
    },
    setDeliveryConstraintsCarrierServiceCapabilities(state, action: PayloadAction<string[]>) {
      const newSet = new Set([...state.overrideValues, 'deliveryConstraints']);
      state.overrideValues = Array.from(newSet);
      state.deliveryConstraints.carrierServiceCapabilities = action.payload;
    },
    postReducerValidateTestInput(state) {
      state.testInputIsValid = validateTestInput(state);
    },
    setQuantity(state, action: PayloadAction<number>) {
      state.quantity = action.payload;
    },
    setRetrieveFulfillmentOptions(state, action: PayloadAction<boolean>) {
      state.retrieveFulfillmentOptions = action.payload;
    },
    setIgnoreRealTimeFactors(state, action: PayloadAction<boolean>) {
      state.ignoreRealTimeFactors = action.payload;
    },
    setPersistResults(state, action: PayloadAction<boolean>) {
      state.persistResults = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchExistingResources.pending, (state) => {
      state.selectedItem = undefined;
    });
    builder.addCase(searchExistingResources.fulfilled, (state, action) => {
      state.selectedItem = undefined;
      state.items = action.payload.items;
      state.orderItemsAreValid = true;
      state.orderItems = _.map(action.payload.items, (item) => ({
        orderedSkuCode: item.orderedSkuCode || item.mcpSku,
        skuCode: item.mcpSku,
        fulfillerId: item.globalFulfillerId,
      }));
    });
    builder.addCase(getDecisionFromItem.fulfilled, (state) => {
      state.retrieveFulfillmentOptions = false;
    });
    builder.addCase(getDecisionFromItem.rejected, (state) => {
      state.retrieveFulfillmentOptions = true;
    });
  },
});

// Run the validateTestInput action after every action
const multiReducer = (state, action) => {
  const intermediateState = testInputSlice.reducer(state, action);

  if (action.type !== testInputSlice.actions.postReducerValidateTestInput.type) {
    return testInputSlice.reducer(
      intermediateState,
      testInputSlice.actions.postReducerValidateTestInput(),
    );
  }

  // If the action was validateTestInput, just return the state
  return intermediateState;
};

export const {
  setSelectedItem,
  setOrderItemsAreValid,
  setOrderItems,
  setFulfillmentCaps,
  setOrderItemsFromRawItems,
  setOrderDate,
  setPromisedArrivalDate,
  setIsPoBox,
  setCountry,
  setPostalCode,
  setQuantity,
  setRetrieveFulfillmentOptions,
  setIgnoreRealTimeFactors,
  setPersistResults,
  setMerchantId,
  setStateOrProvince,
  setDeliveryConstraintsCarrierServiceCapabilities,
  setDeliveryConstraintsPreferredCarrierServices,
  setDeliveryConstraintsCarrierServices,
} = testInputSlice.actions;

export default multiReducer;
