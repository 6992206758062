/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';

import { Action, ActionOperator, ActionV3 } from '../../types';

export function getActionGridStyles({ action }: { action: Action }): SerializedStyles | undefined {
  if (action.type === 'alsoRoutesLegacy') {
    return undefined; // TODO: Replace with better
  }

  const actionV3 = action as ActionV3;

  if (actionV3.operator === ActionOperator.valueInDictionary) {
    return gridStylesRankedTable;
  }

  if (actionV3.type === 'filter') {
    if (actionV3.operator === ActionOperator.margin) {
      return gridStylesMarginFilter;
    }
    return gridStylesStandardFilter;
  } else if (actionV3.type === 'sort') {
    if (actionV3.operator === ActionOperator.margin) {
      return gridStylesMarginSorter;
    }
    return gridStylesStandardSorter;
  }
}

const gridStylesStandardSorter = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px 200px 90px auto 40px;
  grid-template-areas:
    'sort-or-filter parameter operator direction . delete'
    'value . . . . .';

  @media (max-width: 820px) {
    grid-template-columns: minmax(150px, 210px) minmax(100px, 200px) auto 40px;
    grid-template-areas:
      'sort-or-filter parameter . delete'
      'operator value . .'
      'direction . . .';
  }
`;

const gridStylesRankedTable = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px 200px 90px auto 40px;
  grid-template-areas:
    'sort-or-filter parameter operator direction . delete'
    'table . . . . .';

  @media (max-width: 820px) {
    grid-template-columns: minmax(150px, 210px) minmax(100px, 200px) auto 40px;
    grid-template-areas:
      'sort-or-filter parameter . delete'
      'operator direction . .'
      'table . . .';
  }
`;

const gridStylesStandardFilter = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px 200px 150px auto 40px;
  grid-template-areas:
    'sort-or-filter parameter operator value . delete'
    'cause cause cause . . .';

  @media (max-width: 1020px) {
    grid-template-columns: minmax(150px, 210px) minmax(100px, 200px) auto auto 40px;
    grid-template-areas:
      'sort-or-filter parameter . . delete'
      'operator value . . .'
      'cause cause cause . .';
  }
`;

const gridStylesMarginFilter = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px auto 40px;
  grid-template-areas:
    'sort-or-filter parameter . delete'
    'fulfillers percentage . .'
    'cause cause cause .';
`;

const gridStylesMarginSorter = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px 100px auto 40px;
  grid-template-areas:
    'sort-or-filter parameter direction . delete'
    'fulfillers percentage . . .';
`;
