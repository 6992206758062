import { buildOptions, checkResponse } from './serviceHelpers';

export const fetchWithAuth = async <T>({
  endpointUrl,
  method = 'GET',
  route,
  body,
  additionalHeaders = {},
  giveSimpleResponse = true,
  queryParams = {},
}: {
  endpointUrl: string;
  method?: string;
  route?: string;
  body?: string | Record<string, any>;
  additionalHeaders?: Record<string, any>;
  giveSimpleResponse?: boolean;
  queryParams?: Record<string, string>;
}): Promise<T> => {
  const options = buildOptions(method, body, additionalHeaders);
  let fullUrl = route ? `${endpointUrl}/${route}` : endpointUrl;

  if (Object.keys(queryParams).length > 0) {
    const searchParams = new URLSearchParams(queryParams);
    fullUrl += `?${searchParams.toString()}`;
  }

  try {
    const rawResponse = await fetch(fullUrl, options as any);

    const parsedResponse = await checkResponse(
      rawResponse,
      `${method} ${fullUrl}`,
      giveSimpleResponse,
    );
    return parsedResponse;
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    throw error;
  }
};

export default fetchWithAuth;
