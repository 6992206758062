import React from 'react';

import { DecisionViewer } from '@cimpress-technology/routing-decision-viewer-components';

import auth from '../../auth';
import { useAppSelector } from '../../store/hooks';
import styles from './testResults.module.scss';

export default function TestResults() {
  const environment = useAppSelector((state) => state.resourceSearch.environment);
  const routingResults = useAppSelector((state) => state.evaluationResults.results);
  const selectedItem = useAppSelector((state) => state.testInput.selectedItem);

  return (
    <div className={styles.container}>
      {routingResults?._embedded?.decision ? (
        <div>
          <DecisionViewer
            environment={environment}
            accessToken={auth.getAccessToken()}
            decisionJson={JSON.stringify(routingResults._embedded.decision)}
            item={selectedItem as any}
            halfWeight={true}
          />
        </div>
      ) : null}
    </div>
  );
}
