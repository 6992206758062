import _ from 'lodash';
import React, { memo, useRef } from 'react';

import GenericSelector from '@cimpress-technology/generic-selector';

import auth from '../../auth';
import { setProductConfigurationUrl } from '../../features/productConfigurationSearch/productConfigurationSlice';
import { setQuantity } from '../../features/testInput/testInputSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

type AttributeSelectorRef = {
  getConfigurationUrl: () => Promise<string>;
};

function GenericSelectorWrapper({ productId }: { productId?: string }) {
  const selector = useRef<AttributeSelectorRef>();

  const dispatch = useAppDispatch();
  const dispatchProductConfigUrl = (s: string) => dispatch(setProductConfigurationUrl(s));
  const productConfigUrl = useAppSelector(
    (state) => state.productConfiguration.productConfigurationUrl,
  );

  const onLoad = async ({ attributes }) => {
    const quantityAttribute = _.find(attributes, (attribute) => attribute.key === 'Quantity');
    if (quantityAttribute) {
      if (quantityAttribute.resolvedValue) {
        dispatch(setQuantity(quantityAttribute.resolvedValue));
      }
    }
    let productConfigurationUrl = '';
    try {
      productConfigurationUrl = (await selector.current?.getConfigurationUrl()) ?? '';
    } catch {
      // ignore
    }
    dispatchProductConfigUrl(productConfigurationUrl);
  };

  const onChange = async ({ state, attributes }) => {
    if (state === 'fullyResolved') {
      const quantityAttribute = _.find(attributes, (attribute) => attribute.key === 'Quantity');
      if (quantityAttribute) {
        if (quantityAttribute.resolvedValue) {
          dispatch(setQuantity(quantityAttribute.resolvedValue));
        }
      }
      let productConfigurationUrl = '';
      try {
        productConfigurationUrl = (await selector.current?.getConfigurationUrl()) ?? '';
      } catch {
        // ignore
      }
      dispatchProductConfigUrl(productConfigurationUrl);
    }
  };
  return productId ? (
    <GenericSelector
      configurationUrl={productConfigUrl}
      productId={productId}
      authToken={auth.getAccessToken()}
      onChange={onChange}
      onLoad={onLoad}
      ref={(instance: AttributeSelectorRef) => {
        selector.current = instance;
      }}
    />
  ) : (
    <></>
  );
}

export default memo(function MemoizedGenericSelectorWrapper({ productId }: { productId?: string }) {
  return <GenericSelectorWrapper productId={productId} />;
});
