import styled from '@emotion/styled';
import React from 'react';

import { Select, colors } from '@cimpress/react-components';

import { DEFAULT_LABEL, DEFAULT_VALUE } from '../../constants/constants';
import styles from './dictionarySelect.module.scss';

const DictionarySelect = styled(Select)`
  max-width: 200px;
  min-width: 100px;

  .dictionary__control {
    background-color: ${(props) => (props.isDefault ? colors.sky.base : '')};
  }
`;

const CustomOption = ({ innerProps, innerRef, label, data, isFocused, isSelected }) => {
  let classnames = `${styles.option} `;
  if (data.value === DEFAULT_VALUE || data.label === DEFAULT_LABEL) {
    classnames += styles.defaultFulfiller;
  } else if (isSelected) {
    classnames += styles.selected;
  } else if (isFocused) {
    classnames += styles.focused;
  } else {
    classnames += styles.unselected;
  }

  return (
    <div className={classnames} {...innerProps} ref={innerRef}>
      {label}
    </div>
  );
};

const WrappedDictionarySelect = (props) => (
  <DictionarySelect {...props} classNamePrefix="dictionary" components={{ Option: CustomOption }} />
);

export default WrappedDictionarySelect;
