import _ from 'lodash';
import { useEffect, useState } from 'react';

import { getCarrierCapabilities } from '../services/carriers';
import { DisplayValue } from '../types';

export function useCarrierCapabilities() {
  const [carrierCapabilitiesData, setCarrierCapabilitiesData] = useState<DisplayValue<string>[]>(
    [],
  );

  useEffect(() => {
    const fetchCarrierCapabilities = async () => {
      const _carrierServices = await getCarrierCapabilities();
      const data = _carrierServices.map((cs) => ({ label: cs.name, value: cs.key }));
      setCarrierCapabilitiesData(data);
    };

    if (_.isEmpty(carrierCapabilitiesData)) {
      fetchCarrierCapabilities();
    }
  }, []);

  return carrierCapabilitiesData;
}
