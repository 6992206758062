import _ from 'lodash';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Spinner } from '@cimpress/react-components';

import { setError } from '../features/alerts/alertContentSlice';
import { setProductConfigurationUrl } from '../features/productConfigurationSearch/productConfigurationSlice';
import { setItemOrOrderId } from '../features/resourceSearch/resourceSearchSlice';
import {
  fetchRoutingConfigByBuyerAndSku,
  fetchRoutingConfigById,
} from '../features/selectedConfiguration/actions';
import { setSkuCode } from '../features/selectedConfiguration/selectedConfigurationSlice';
import { selectTesterTab } from '../features/tabs/tabSlice';
import { setSelectedItem } from '../features/testInput/testInputSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';

export default function ResourceLoader({ children }) {
  const navigate = useNavigate();
  const { id, accountId } = useParams();
  const { pathname, search } = useLocation();

  const skuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);
  const selectedTabIndex = useAppSelector((state) => state.tab.selectedTabIndex);
  const selectedConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  );
  const error = useAppSelector((state) => state.alertContent.error);

  const needsToLoad = (!skuCode || !selectedConfiguration) && !pathname.includes('evaluate');

  const isLoadingSelectedConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.loading,
  );

  const dispatch = useAppDispatch();

  const clearSelectedRouteTesterItem = () => {
    dispatch(setProductConfigurationUrl(''));
    dispatch(setSelectedItem());
    dispatch(setItemOrOrderId());
  };

  const dispatchSkuCode = (sku: string) => dispatch(setSkuCode(sku));

  /* The following states are possible during navigation:
  /configurations/edit/{id} ->
    - We have an ID. If so, fetch a config if we don't have one.
        - If account ID is missing from the URL, populate it from the config.
    - Set builder tab as active tab.
    - On error, go back to /configurations.
  /configurations/{id} ->
    - We have an ID but the route is wrong. Fetch the config, and navigate to /edit/id.
        - If account ID is missing from the URL, populate it from the config.
    - Set builder tab as active tab.
    - On error, go back to /configurations. 
  /configurations/create ->
    - We have a create route. Attempt to fetch by buyer and sku.
    - Set builder tab as active tab.
    - If we are missing either account ID or skuCode, go back to /configurations.

  /configurations/evaluate ->
    - Set tester tab as active tab.

  This boils down to only navigation...
    Which we do here:
    - Adding in an account ID, i.e. correcting the URL.
    - Going to /edit/id if the routing config has an ID in it, i.e. correcting the URL.

    Which we do in other places:
    - Tab-related navigation. The TabContainer handles this.
    - Navigating to /edit/id immediately upon successfully publishing.
   */

  // Fetch routing configuration if necessary
  useEffect(() => {
    if (!needsToLoad) {
      return;
    }

    if (id) {
      dispatch(fetchRoutingConfigById(id));
      return;
    }

    if (pathname.includes('create') && accountId) {
      const skuCodeFromParams = new URLSearchParams(search).get('skuCode');
      if (skuCodeFromParams) {
        dispatchSkuCode(skuCodeFromParams);
        dispatch(
          fetchRoutingConfigByBuyerAndSku({
            buyer: accountId,
            skuCode: skuCodeFromParams,
          }),
        );
      } else {
        dispatch(
          setError({
            title: 'Navigation Error',
            messages: ['No skuCode was found in the URL.'],
            error: 'No skuCode was found in the URL.',
          }),
        );
        navigate(`/accounts/${accountId}/configurations/`);
      }
    }
  }, [id, dispatch, skuCode, accountId, error]);

  // Navigate to new URL if necessary
  useEffect(() => {
    if (needsToLoad || isLoadingSelectedConfiguration) {
      return;
    }

    if (error) {
      if (accountId) {
        navigate(`/accounts/${accountId}/configurations/`);
      } else {
        navigate('/configurations/');
      }
    }

    if (pathname.includes('evaluate')) {
      dispatch(selectTesterTab());
      return;
    }

    if (selectedConfiguration?.id && selectedConfiguration.id !== 'default' && !id) {
      navigate(`/accounts/${accountId}/configurations/edit/${selectedConfiguration.id}`);
    }

    // Check to see if we don't have enough info
    if (
      !selectedConfiguration && // If we don't already have a configuration,
      !id && // and we don't have an ID to search by,
      !(skuCode && accountId) && // and we don't have a buyer + SKU to search by,
      selectedTabIndex === 0 // and we're on the builder tab...
    ) {
      // Otherwise, we don't have enough info and need to start from scratch.
      clearSelectedRouteTesterItem();
      dispatch(
        setError({
          title: 'Navigation Error',
          messages: ['Not enough information; did you use an incomplete URL?'],
        }),
      );
      if (accountId) {
        navigate(`/accounts/${accountId}/configurations/`);
      } else {
        navigate('/configurations/');
      }
    }

    // Okay, we have enough info; let's see if we need to adjust the URL at all
    if (!accountId && selectedConfiguration) {
      const [, rest] = _.split(pathname, '/configurations/');
      navigate(`/accounts/${selectedConfiguration.accountId}/configurations/${rest}`);
      return;
    }

    if (id && !pathname.includes('edit')) {
      if (accountId) {
        navigate(`/accounts/${accountId}/configurations/edit/${id}`);
      } else if (selectedConfiguration?.accountId) {
        navigate(`/accounts/${selectedConfiguration.accountId}/configurations/edit/${id}`);
      }
    }
  }, [
    id,
    needsToLoad,
    isLoadingSelectedConfiguration,
    JSON.stringify(selectedConfiguration),
    accountId,
  ]);

  if (needsToLoad) {
    return <Spinner fullPage />;
  }

  return children;
}
