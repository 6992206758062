/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React from 'react';

import { colors } from '@cimpress/react-components';

import { allowedDictionaryRanks } from '../../constants/allowedDictionaryRanks';
import { DEFAULT_LABEL, DEFAULT_VALUE } from '../../constants/constants';
import { DisplayValue } from '../../types';
import StyledTextField from '../styledComponents/StyledTextField';
import TrashIconButton from '../styledComponents/TrashIconButton';
import DictionarySelect from './DictionarySelect';

export default function RankedDictionaryRow({
  actionId,
  selectedKey,
  onChangeSelectedKey,
  selectedValue,
  onChangeSelectedValue,
  allowedKeys,
  onDelete,
  focusedInput,
  onFocus,
  inputName,
  allowTextInput,
}: {
  actionId: string;
  selectedKey: DisplayValue<string>;
  onChangeSelectedKey: ({ value }) => void;
  selectedValue: DisplayValue<string | number>;
  onChangeSelectedValue: ({ value }) => void;
  allowedKeys: DisplayValue<string>[];
  onDelete: () => void;
  focusedInput: string | null;
  onFocus: (input: string) => void;
  inputName: string;
  allowTextInput: boolean;
}) {
  const focusedKeyId = `${actionId}-dictionary-key-${selectedKey.value}`;
  const focusedValueId = `${actionId}-dictionary-value-${selectedValue}`;

  const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChangeSelectedKey({ value });
    onFocus(`${actionId}-dictionary-key-${value}`);
  };

  let textboxInputStyles = {};
  if (selectedKey?.value === DEFAULT_VALUE || selectedKey.label === DEFAULT_LABEL) {
    textboxInputStyles = defaultSelectedTextbox;
  }

  const optionAttributeSelect = allowTextInput ? (
    <StyledTextField
      label={`Enter ${inputName}`}
      value={selectedKey.value}
      onChange={handleTextInput}
      autoFocus={focusedInput === focusedKeyId}
      onFocus={() => onFocus(focusedKeyId)}
      inputStyles={textboxInputStyles}
    />
  ) : (
    <DictionarySelect
      label={`Enter ${inputName}`}
      options={_.concat(allowedKeys, [selectedKey])}
      value={selectedKey}
      onChange={onChangeSelectedKey}
      autoFocus={focusedInput === focusedKeyId}
      onFocusInput={() => onFocus(focusedKeyId)}
      classNamePrefix={'dictionary'}
      isDefault={selectedKey?.value === DEFAULT_VALUE}
    />
  );

  return (
    <>
      <div css={flexDiv}>
        {optionAttributeSelect}
        <DictionarySelect
          label="Priority"
          options={allowedDictionaryRanks}
          value={selectedValue}
          onChange={onChangeSelectedValue}
          autoFocus={focusedInput === focusedValueId}
          onFocusInput={() => onFocus(focusedValueId)}
        />
        <TrashIconButton onDelete={onDelete} />
      </div>
    </>
  );
}

const flexDiv = css`
  display: flex;
  gap: 10px;
`;

const defaultSelectedTextbox = {
  backgroundColor: colors.sky.base,
};
