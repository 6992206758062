import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getMappingByConfigurationId,
  getRoutingConfigurationByBuyerAndSku,
  getRoutingConfigurationById,
} from '../../services/routingConfigurationService';
import { hydrateRoutingConfiguration } from '../../utils/hydrate';
import { makeErrorSerializable } from '../../utils/makeErrorSerializable';
import { scrollToTop } from '../../utils/windowScroll';

type SearchAndSetParams = {
  buyer: string;
  skuCode: string;
};

export const fetchRoutingConfigByBuyerAndSku = createAsyncThunk(
  'selectedConfiguration/fetchRoutingConfigByBuyerAndSku',
  async ({ buyer, skuCode }: SearchAndSetParams, { rejectWithValue }) => {
    try {
      const { configuration, etag } = await getRoutingConfigurationByBuyerAndSku({
        buyer,
        skuCode,
      });
      return {
        configuration,
        hydratedConfiguration: hydrateRoutingConfiguration(configuration),
        etag,
        skuCode,
      };
    } catch (error) {
      scrollToTop();
      return rejectWithValue(makeErrorSerializable(error));
    }
  },
);

export const fetchRoutingConfigById = createAsyncThunk(
  'selectedConfiguration/fetchRoutingConfigById',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const { configuration, etag } = await getRoutingConfigurationById(id);
      let skuCode: string | undefined;
      if (id !== 'default') {
        skuCode = await dispatch(fetchSkuCodeFromRoutingConfigId(id)).unwrap();
      }
      return {
        configuration,
        hydratedConfiguration: hydrateRoutingConfiguration(configuration),
        etag,
        skuCode,
      };
    } catch (error) {
      scrollToTop();
      return rejectWithValue(makeErrorSerializable(error));
    }
  },
);

export const fetchSkuCodeFromRoutingConfigId = createAsyncThunk(
  'selectedConfiguration/fetchSkuCodeFromRoutingConfigId',
  async (id: string, { rejectWithValue }) => {
    try {
      const mapping = await getMappingByConfigurationId(id);
      return mapping?.referenceId;
    } catch (error) {
      scrollToTop();
      return rejectWithValue(makeErrorSerializable(error));
    }
  },
);
