import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_LABEL, DEFAULT_VALUE } from '../constants/constants';
import { DisplayValue } from '../types';
import { getFulfillers } from '../utils/getFulfillers';

export function useFulfillersWithDefault() {
  const [fulfillerData, setFulfillerData] = useState<Record<string, DisplayValue<string>>>({});
  const { accountId } = useParams();

  useEffect(() => {
    const fetchFulfillers = async () => {
      const _fulfillers = await getFulfillers();
      const data = _(_fulfillers)
        .entries()
        .reduce(
          (accumulator, [fulfillerId, { name }]) => {
            accumulator[fulfillerId] = { label: name, value: fulfillerId };
            return accumulator;
          },
          {} as Record<string, DisplayValue<string>>,
        );

      delete data['nahxne6te2']; // named 'Default'

      data[DEFAULT_VALUE] = {
        label: DEFAULT_LABEL,
        value: DEFAULT_VALUE,
      };

      setFulfillerData(data);
    };

    if (_.isEmpty(fulfillerData)) {
      fetchFulfillers();
    }
  }, [accountId]);

  return fulfillerData;
}
