import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { Accordion, Checkbox, DatePicker, Select, Tooltip } from '@cimpress/react-components';

import {
  setCountry,
  setDeliveryConstraintsCarrierServiceCapabilities,
  setDeliveryConstraintsCarrierServices,
  setDeliveryConstraintsPreferredCarrierServices,
  setFulfillmentCaps,
  setIgnoreRealTimeFactors,
  setIsPoBox,
  setMerchantId,
  setOrderDate,
  setPersistResults,
  setPostalCode,
  setPromisedArrivalDate,
  setRetrieveFulfillmentOptions,
  setStateOrProvince,
} from '../../features/testInput/testInputSlice';
import { useCarrierCapabilities } from '../../hooks/useCarrierCapabilities';
import { useCarrierServices } from '../../hooks/useCarrierServices';
import { useMerchants } from '../../hooks/useMerchants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DisplayValue } from '../../types';
import { scrollToBottom } from '../../utils/windowScroll';
import { canadaProvinces, usStates } from '../geocode/GeocodeRow';
import GeocodeStateSelect, { GeocodeCreatableStateSelect } from '../geocode/GeocodeStateSelect';
import StyledTextField from '../styledComponents/StyledTextField';
import GenericSelectorWrapper from './GenericSelectorWrapper';
import CountrySelector from './inputs/CountrySelector';
import FulfillmentCapabilitiesSelector from './inputs/FulfillmentCapabilitiesSelector';
import styles from './testerFormFields.module.scss';

export default function TesterFormFields() {
  const dispatch = useAppDispatch();

  const skuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);

  const productConfigurationUrl = useAppSelector(
    (state) => state.productConfiguration.productConfigurationUrl,
  );

  const fulfillmentOptionsLink = useAppSelector(
    (state) => state.resourceSearch.fulfillmentOptionsLink,
  );

  const merchantId = useAppSelector((state) => state.testInput.merchantId);
  const merchants = useMerchants();

  const orderDate = useAppSelector((state) => state.testInput.orderDate);
  const promisedArrivalDate = useAppSelector((state) => state.testInput.promisedArrivalDate);
  // const usingPickupPoint = useAppSelector((state) => state.testInput.usingPickupPoint);

  const country = useAppSelector((state) => state.testInput.country);
  const postalCode = useAppSelector((state) => state.testInput.postalCode);
  const stateOrProvince = useAppSelector((state) => state.testInput.stateOrProvince);
  const isPoBox = useAppSelector((state) => state.testInput.isPoBox);

  const deliveryConstraints = useAppSelector((state) => state.testInput.deliveryConstraints);

  const carrierServices = useCarrierServices();
  const [chosenCarrierServices, remainingCarrierServices] = _.partition(
    carrierServices,
    (cs) => deliveryConstraints.carrierServices?.includes(cs.value),
  );
  const [chosenPreferredCarrierServices, remainingPreferredCarrierServices] = _.partition(
    carrierServices,
    (cs) => deliveryConstraints.preferredCarrierServices?.includes(cs.value),
  );

  const carrierCapabilities = useCarrierCapabilities();
  const [chosenCarrierCapabilities, remainingCarrierCapabilities] = _.partition(
    carrierCapabilities,
    (cs) => deliveryConstraints.carrierServiceCapabilities?.includes(cs.value),
  );

  const fulfillmentCaps = useAppSelector((state) => state.testInput.fulfillmentCaps);

  const retrieveFulfillmentOptions = useAppSelector(
    (state) => state.testInput.retrieveFulfillmentOptions,
  );
  const ignoreRealTimeFactors = useAppSelector((state) => state.testInput.ignoreRealTimeFactors);
  const persistResults = useAppSelector((state) => state.testInput.persistResults);

  const dispatchOrderDate = (date) => {
    dispatch(setOrderDate(moment.isMoment(date) ? date.format('YYYY-MM-DDTHH:mm:ss[Z]') : date));
  };

  const dispatchPromisedArrivalDate = (date) => {
    dispatch(setPromisedArrivalDate(moment.isMoment(date) ? date.format('YYYY-MM-DD') : date));
  };

  const dispatchCountry = (_country: string | null) => {
    dispatch(setCountry(_country));
  };

  const dispatchPostalCode = (_postalCode: string) => {
    dispatch(setPostalCode(_postalCode));
  };

  const dispatchMerchantId = (_merchantId: DisplayValue<string>) => {
    dispatch(setMerchantId(_merchantId.value));
  };

  const dispatchStateOrProvince = (_stateOrProvince: DisplayValue<string>) => {
    dispatch(setStateOrProvince(_stateOrProvince.value));
  };

  const dispatchDeliveryConstraintsCarrierServices = (_carrierServices: DisplayValue<string>[]) => {
    dispatch(setDeliveryConstraintsCarrierServices(_carrierServices.map((c) => c.value)));
  };

  const dispatchDeliveryConstraintsPreferredCarrierServices = (
    _preferredCarrierServices: DisplayValue<string>[],
  ) => {
    dispatch(
      setDeliveryConstraintsPreferredCarrierServices(_preferredCarrierServices.map((c) => c.value)),
    );
  };

  const dispatchDeliveryConstraintsCarrierServiceCapabilities = (
    _capabilities: DisplayValue<string>[],
  ) => {
    dispatch(setDeliveryConstraintsCarrierServiceCapabilities(_capabilities.map((c) => c.value)));
  };

  const toolTipTopLevelStyle = {
    zIndex: 6,
  };

  return (
    <div>
      <h3 className={styles.semiBold}>Order Criteria</h3>

      <Accordion variant="ghost" title={<div>Item Details</div>}>
        <Tooltip style={toolTipTopLevelStyle} contents={<p>Replaces the merchantId on the item</p>}>
          <Select
            label="Merchant Id"
            value={{ label: merchantId, value: merchantId }}
            onChange={(e) => dispatchMerchantId(e)}
            options={Object.values(merchants) || [{ label: merchantId, value: merchantId }]}
            required
          />
        </Tooltip>
        <div className={styles.datePickerWrapper}>
          <Tooltip
            style={toolTipTopLevelStyle}
            contents={
              <p>
                Change the date the item reached routing (does not look up historical data, mainly
                useful for the Promised Arrival Date condition)
              </p>
            }
          >
            <DatePicker
              label="Order date"
              value={moment(orderDate)}
              onChange={dispatchOrderDate}
              onClickInput={scrollToBottom}
              required
            />
          </Tooltip>
        </div>
      </Accordion>

      <Accordion variant="ghost" title={<div>Product Settings</div>}>
        {productConfigurationUrl && (
          <div>
            <GenericSelectorWrapper productId={skuCode} />
            View product configuration URL for SKU&nbsp;
            <a href={productConfigurationUrl}>{skuCode}</a>
          </div>
        )}
      </Accordion>

      <div>
        <Accordion
          variant="ghost"
          title={
            <div>
              Shipping Information{' '}
              {(!country || !postalCode || !promisedArrivalDate) && (
                <span className={styles.required}>*</span>
              )}
            </div>
          }
        >
          <div className={styles.datePickerWrapper}>
            <Tooltip
              style={toolTipTopLevelStyle}
              contents={
                <p>
                  Replaces the promised arrival date on the item, useful for replacement option
                  retrieval and the Promised Arrival Date condition
                </p>
              }
            >
              <DatePicker
                label="Promised arrival date"
                value={moment(promisedArrivalDate)}
                onChange={dispatchPromisedArrivalDate}
                onClickInput={scrollToBottom}
                required
              />
            </Tooltip>
          </div>
          {(!country || !postalCode) && (
            <p className={styles.errorText}>Please enter destination information</p>
          )}

          <div>
            <CountrySelector
              value={country}
              onChange={dispatchCountry}
              isDisabled={false}
              // isDisabled={usingPickupPoint}
            />
            <StyledTextField
              value={postalCode}
              label="Postal Code"
              onChange={(e) => dispatchPostalCode(e.target.value)}
              // disabled={usingPickupPoint}
              className={styles.postalCode}
              required
            />
            {country === 'US' && (
              <GeocodeStateSelect
                value={usStates.find((s) => s.value === stateOrProvince)}
                label="State"
                options={usStates}
                onChange={dispatchStateOrProvince}
                inputMode="text"
                isMulti={false}
              />
            )}
            {country === 'CA' && (
              <GeocodeStateSelect
                value={canadaProvinces.find((s) => s.value === stateOrProvince)}
                label="Province"
                options={canadaProvinces}
                onChange={dispatchStateOrProvince}
                inputMode="text"
                isMulti={false}
              />
            )}
            {!['US', 'CA'].includes(country || 'US') && (
              <GeocodeCreatableStateSelect
                value={
                  stateOrProvince ? { value: stateOrProvince, label: stateOrProvince } : undefined
                }
                label="State Or Province"
                onChange={dispatchStateOrProvince}
                inputMode="text"
                isMulti={false}
              />
            )}
            <Checkbox
              checked={isPoBox}
              label="PO Box"
              onChange={(e) => dispatch(setIsPoBox(e.target.checked))}
              // disabled={usingPickupPoint}
              className={styles.poBox}
            />
          </div>

          <h4 className={styles.semiBold}>Delivery Constraints</h4>
          <Select
            label="Carrier Services"
            value={chosenCarrierServices}
            onChange={dispatchDeliveryConstraintsCarrierServices}
            options={remainingCarrierServices}
            isMulti
            isClearable
          />
          <Select
            label="Preferred Carrier Services"
            value={chosenPreferredCarrierServices}
            onChange={dispatchDeliveryConstraintsPreferredCarrierServices}
            options={remainingPreferredCarrierServices}
            isMulti
            isClearable
          />
          <Select
            label="Carrier Service Capabilities"
            value={chosenCarrierCapabilities}
            onChange={dispatchDeliveryConstraintsCarrierServiceCapabilities}
            options={remainingCarrierCapabilities}
            isMulti
            isClearable
          />
        </Accordion>
        <Accordion variant="ghost" title={<div>Fulfillment Options</div>}>
          <h4 className={styles.semiBold}>Capabilities</h4>
          <FulfillmentCapabilitiesSelector
            value={fulfillmentCaps}
            onChange={(caps: string[]) => dispatch(setFulfillmentCaps(caps))}
          />
          <h4 className={styles.semiBold}>Fulfillment Options</h4>
          <Tooltip
            style={toolTipTopLevelStyle}
            contents={
              <p>
                Replaces the fulfillment options the selected item used and retrieves new ones.
                Recommended to use when you want to override values on item or you have made changes
                to equivalency, FSs, FFM, etc..
              </p>
            }
          >
            <Checkbox
              checked={retrieveFulfillmentOptions}
              label="Retrieve Replacement Fulfillment Options"
              onChange={(e) => dispatch(setRetrieveFulfillmentOptions(e.target.checked))}
              className={styles.poBox}
              disabled={!fulfillmentOptionsLink}
            />
          </Tooltip>
          <div></div>
          <Tooltip
            style={toolTipTopLevelStyle}
            contents={
              <p>Ignores current capacity and inventory when evaluating fulfillment options.</p>
            }
          >
            <Checkbox
              checked={ignoreRealTimeFactors}
              label="Ignore Real Time Factors"
              onChange={(e) => dispatch(setIgnoreRealTimeFactors(e.target.checked))}
              disabled={!retrieveFulfillmentOptions}
              className={styles.poBox}
            />
          </Tooltip>
        </Accordion>
        <Accordion variant="ghost" title={<div>Other</div>}>
          {' '}
          <Tooltip
            style={toolTipTopLevelStyle}
            contents={
              <p>
                This will save the test results and return a link to the resulting decision. Only
                use this if you want to share the results.
              </p>
            }
          >
            <Checkbox
              checked={persistResults}
              label="Save Test Result"
              onChange={(e) => dispatch(setPersistResults(e.target.checked))}
              className={styles.poBox}
            />
          </Tooltip>
        </Accordion>
        {/* TODO - these commented out sections will eventually be enabled... 
        but not in the MVP. */}
      </div>

      {/* <div>
        <DottedLine />
        <h4 className={`${styles.header} ${styles.semiBold}`}> Or </h4>
        <DottedLine />
      </div>
      <Checkbox
        checked={usingPickupPoint}
        label="Is pickup point"
        onChange={(e) => dispatch(setUsingPickupPoint(e.target.checked))}
        className={styles.poBox}
      /> */}

      {/* 
      <h3 className={styles.semiBold}>Order Metadata</h3>
      <Tip>
        <p>These represent other items on the order.</p>
        <p>
          To represent an item that was ordered but has not been routed, enter an OrderedSku but no
          Routed SKU or Fulfiller ID.
        </p>
        <p>
          To represent an item that has already been routed, enter an OrderedSku and one (or both)
          of Routed SKU and Fulfiller ID.
        </p>
        <p>
          If you load an existing item from an order, these will be populated automatically with the
          items found on that order.
        </p>
      </Tip>
      <OrderItems
        orderItems={orderItems}
        setOrderItems={(items) => dispatch(setOrderItems(items))}
        isValid={orderItemsAreValid}
        setIsValid={setOrderItemsAreValid}
      /> */}
    </div>
  );
}
