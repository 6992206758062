import React, { Fragment, useEffect, useState } from 'react';
import { Link, Outlet, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import { LoudspeakerIcon } from '@cimpress-technology/react-loudspeaker';
import Header from '@cimpress-technology/react-platform-header';
import { GlobalStyles } from '@cimpress/react-components';

import auth from '../auth';
import { DOCUMENTATION_LINK } from '../constants/constants';
import parseUrlSections from '../utils/parseUrlSections';
import styles from './addElementButton.module.scss';
import { GetAppLinks } from './headerLinks/GetAppLinks';

const AppTitle = ({ title, accountId }) => (
  <Link to={`/accounts/${accountId}`} style={{ textDecoration: 'none' }}>
    {title}
  </Link>
);

const RoutingConfigLoudspeakerIcon = () => (
  <LoudspeakerIcon
    accessToken={auth.getAccessToken()}
    channelIds={['noms-news']}
    title="What's new with Routing Configuration?"
  />
);

export default function Shell(props) {
  const { requireAccount = true, showAccountSelector = true } = props;
  const { accountId } = useParams();
  const [account, setAccount] = useState({ accountId });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!requireAccount) {
      return;
    }

    const { basePath, restOfUrl } = parseUrlSections(
      location.pathname,
      new URLSearchParams(location.search),
    );
    const pathToGenerate = `${basePath}/accounts/:accountId/${restOfUrl}`;

    if (account && account.accountId) {
      navigate(generatePath(pathToGenerate, { accountId: account.accountId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Fragment>
      <div className={styles.topLevel}>
        <GlobalStyles />
        <Header
          responsive
          profile={auth.getProfile()}
          appTitle={<AppTitle title="Routing Configuration" accountId={account.accountId} />}
          onLogInClicked={() => auth.login({ nextUri: '/' })}
          onLogOutClicked={() => auth.logout('/')}
          isLoggedIn={auth.isLoggedIn()}
          accessToken={auth.getAccessToken()}
          notifications={<RoutingConfigLoudspeakerIcon />}
          appLinks={GetAppLinks({ showAccountSelector, auth, account, setAccount })}
          documentationLink={DOCUMENTATION_LINK}
          isProduction={process.env.NODE_ENV === 'production'}
        />
        <div className={styles.outlet}>
          <Outlet />
        </div>
      </div>
    </Fragment>
  );
}
