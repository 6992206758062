import { ActionOperator, LinkWithName, RoutingConfigurationV3 } from '../types';
import fetchWithAuth from '../utils/fetchWithAuth';
import { Problem } from '../utils/isValidPath';
import { Decision } from './decisions';
import { DeliveryConfiguration, DeliveryRequest, Item, ItemFulfillmentCapability } from './items';

export type RouteV0RequestOverrideDeliveryDetails = {
  country?: string;
  pickupPointUrl?: string;
  postalCode?: string;
  isPOBox?: boolean;
  stateOrProvince?: string;
  deliveryConstraints?: any;
};

export type RouteV0RequestOverrideValues = {
  merchantId?: string;
  productConfigurationUrl?: string;
  fulfillmentCapabilities?: string[];
  localPromisedArrivalDate?: string;
  routingTime?: string;
  deliveryDetails?: RouteV0RequestOverrideDeliveryDetails;
};

export type RouteV0RequestBody = {
  fulfillmentOptions?: TestFulfillmentOptionV1[];
  routingConfiguration?: RoutingConfigurationV3;
  item?: TestItem | Item;
  routingRequest?: TestRoutingRequestRecord;
  metadata?: {
    orderItems?: {
      orderedSkuCode: string;
      skuCode?: string;
      fulfillerId?: string;
    }[];
  };
  overrideValues?: RouteV0RequestOverrideValues;
  hidePrices?: boolean;
  includeCurrentInfo?: boolean;
  test?: boolean;
  _links: {
    buyerAccount: LinkWithName;
    item?: LinkWithName;
    routingConfiguration?: LinkWithName;
    fulfillmentOptions?: LinkWithName;
    routingRequest?: LinkWithName;
  };
};

export type TestFulfillmentOptionV1 = {
  optionId: string;
  isValid: boolean;
  problem?: Problem;
  mcpSku?: string;
  isOutOfStock?: boolean;
  priceIncludingCostOfDelay?: string | number;
  priceIncludingAssumedInHouseCost?: string | number;
  fulfillerId: string;
  capacityAvailability?: {
    isCapacityConfigured: boolean;
    hasAvailableCapacity: boolean;
    metMinCapacity: boolean;
    errorCalculatingCapacity: boolean;
  };
  _embedded: {
    deliveryFeasibility?: {
      lateArrival?: boolean;
    };
  };
};

export type TestItem = {
  merchantId: string;
  itemId: string;
  orderedSkuCode: string;
  skuCode: string;
  quantity: number;
  localPromisedArrivalDate: string;
  deliveryRequest?: DeliveryRequest;
  deliveryConfigurations?: DeliveryConfiguration[];
  fulfillmentCapabilities?: ItemFulfillmentCapability[];
};

export type TestRoutingRequestRecord = {
  requestBody: TestRoutingRequest;
};

export type TestRoutingRequest = {
  items: TestRoutingRequestItemData[];
};

export type TestRoutingRequestItemData = {
  itemId: string;
};

export type RoutingResult = {
  _embedded: {
    decision: Decision;
    routingReceipts: RoutingReceipt[];
  };
  _links: {
    item: LinkWithName;
    decision: LinkWithName;
    fulfillmentOptions: LinkWithName;
    routingConfiguration: LinkWithName;
    routingRequest: LinkWithName;
    networkOperator: LinkWithName;
  };
};

export type RoutingReceipt = ReceiptEntry[];

export type ReceiptEntry = EventLog | ActionLog | NodeBeginOrEndLog | ErrorLog;

export enum LogType {
  'begin' = 'begin',
  'end' = 'end',
}

export type ActionLog = {
  actionId: string;
  action: 'filter' | 'sort' | 'alsoRoutesLegacy';
  path: string;
  operator: ActionOperator;
  value: any;
  direction?: 'asc' | 'desc';
  optionState: OptionLogState[];
};

export type OptionLogState = {
  optionId?: string;
  weights?: number[];
  isValid?: boolean;
};

export type ConditionReceipt = {
  result: boolean;
  description: string;
};

export type EventLog = {
  event: string;
  success: boolean;
  nextNodeId?: string;
  actionId?: string;
  executedConditions?: ConditionReceipt[];
};

export type NodeBeginOrEndLog = {
  nodeId: string;
  name?: string;
  description?: string;
  type: LogType;
};

export type ErrorLog = {
  error: string;
};

export const evaluateRoutingConfiguration = async (
  body: RouteV0RequestBody,
): Promise<RoutingResult> => {
  return await fetchWithAuth<RoutingResult>({
    endpointUrl: process.env.REACT_APP_ORCA_URL + 'v0/route',
    method: 'POST',
    body,
  });
};
